<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    class="core-navigation-drawer"
    color="background"
    v-model="drawer"
    :expand-on-hover="false"
    mobile-breakpoint="960"
    app
    width="220"
    
  >
    <v-divider class="mb-1" />

    <v-container
      class="pa-2 text-center">
      <!-- <v-icon large color="grey darken-3">mdi-truck-fast</v-icon> -->
      <v-img :src="icon" max-height="50px" contain/>  
      <h5>{{ $appName }}</h5>
    </v-container>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
      dense
    >
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',
    data: () => ({
      icon: require('@/assets/favicon-192.png'),
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'Dashboard',
          to: '/auth/dashboard',
        },
        {
          icon: 'mdi-package-variant',
          title: 'Fulfill',
          to: '/auth/fulfill',
          scope: 'fulfill',
          hide: process.env.VUE_APP_MODE !== 'fh',
        },
        {
          icon: 'mdi-warehouse',
          title: 'Warehouse',
          to: '/auth/warehouse',
          scope: 'warehouse',
          hide: process.env.VUE_APP_MODE !== 'fh',
        },
        {
          icon: 'mdi-airplane-takeoff',
          title: 'Shipments',
          to: '/auth/shipments',
          scope: 'shipments',
          hide: process.env.VUE_APP_MODE !== 'fh',
        },
        {
          icon: 'mdi-bell-badge',
          title: 'Blocks & Logs',
          to: '/auth/logs',
          scope: 'logs',
          hide: process.env.VUE_APP_MODE !== 'fh',
        },
        {
          icon: 'mdi-shield-lock-open',
          title: 'Plans',
          to: '/auth/plans',
          scope: 'plans',
        },
        {
          icon: 'mdi-lifebuoy',
          title: 'Support',
          to: '/auth/support',
        },
        {
          icon: 'mdi-cog',
          title: 'Settings',
          to: '/auth/settings',
          id: 'settings',
          scope: 'settings.*',
          hide: process.env.VUE_APP_MODE !== 'postnord',
        },
        {
          icon: 'mdi-cog',
          title: 'Settings',
          to: '/auth/settings',
          id: 'settings',
          scope: 'settings.*',
          hide: process.env.VUE_APP_MODE !== 'fh',
          children: [
            {
              icon: 'mdi-warehouse',
              title: 'Fulfillment & Products',
              to: '/auth/settings/company',
              scope: 'settings.company',
              hide: process.env.VUE_APP_MODE !== 'fh',
            },
            {
              icon: 'mdi-store',
              title: 'Stores',
              to: '/auth/settings/stores',
              scope: 'settings.stores',
              hide: process.env.VUE_APP_MODE !== 'fh',
            },
            {
              icon: 'mdi-truck',
              title: 'Carriers',
              to: '/auth/settings/carriers',
              scope: 'settings.carriers',
              hide: process.env.VUE_APP_MODE !== 'fh',
            },
            // {
            //   icon: 'mdi-printer-wireless',
            //   title: 'Printers',
            //   to: '/auth/settings/printers',
            //   scope: 'settings.printers'
            // },
            {
              icon: 'mdi-account-multiple',
              title: 'Users',
              to: '/auth/settings/users',
              scope: 'settings.users',
              hide: process.env.VUE_APP_MODE !== 'fh',
            },
          ]
        },
      ],
    }),

    computed: {
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.$store.commit('app/SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items
          .filter(i => !i.hide)
          .map(this.mapItem)
      },
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_user_scope: 'user/get_user_scope',
    })
    },

    methods: {
      filterItem(item) {
        return item.scope ? this.get_user_scope(item.scope) : true
      },
      mapItem (item) {
        return {
          ...item,
          disabled: item.scope ? !this.get_user_scope(item.scope) : false,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          warning: this.get_printing_possible && !this.$store.state.warehouse.company.print_server_connected && item.id === 'settings',
        }
      },
    },
    mounted(){
      console.log(this.$router.matcher.getRoutes());
    }
  }
</script>
<style lang="scss" scoped>

::v-deep.core-navigation-drawer {
  padding-bottom: env(safe-area-inset-bottom) !important;
}
   .v-list-item__title {
    font-size: 0.9rem
  }
</style>

